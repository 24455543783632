<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <BasicTop :basicTop="basicTop" :numberrows="numberrows" />
      <Steps
        :levels="levels"
        v-if="levels && appStatus == 1"
        :hierarchy="hierarchy"
        :showZb="details.showZb"
      />
      <div class="content-show">
        <van-tabs v-model="active" :animated="true">
          <van-tab title="详情页" name="a">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item
                  title="招标项目基本信息"
                  name="1"
                  icon="label"
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="招标委托单名称"
                      :value="details.consignname"
                    />
                    <van-cell title="招标项目单位" :value="details.item" />
                    <van-cell title="项目批准文号" :value="details.itemcode" />
                    <van-cell title="项目名称" :value="details.itemname" />
                    <van-cell
                      title="资金性质"
                      :value="details.ispublicmoneyName"
                    />
                    <van-cell
                      title="资金来源"
                      :value="details.moneysourceName"
                    />
                    <van-cell title="需求类型" :value="details.reqtypeName" />
                    <van-cell
                      title="是否市场化招标"
                      :value="details.marketbid == 1 ? '是' : '否'"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="招标物资信息" name="2" icon="label">
                  <van-cell-group class="group">
                    <van-cell
                      title="分包方式"
                      :value="
                        details.packway == 1 ? '按企业分包' : '按物资分包'
                      "
                    />
                    <van-cell title="招标物资" :value="details.goods" />
                    <van-cell title="招标物资数量" :value="details.amount" />
                    <van-cell title="概算金额(万元)" :value="details.money" />
                    <!-- <van-cell title="附件">
                      <div>{{}}</div>
                    </van-cell> -->
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="开标信息" name="3" icon="label">
                  <van-cell-group class="group">
                    <van-cell
                      title="招标方式"
                      :value="details.tendermodeName"
                    />
                    <van-cell
                      title="资审方式"
                      :value="
                        details.checktype == 1
                          ? '资格预审'
                          : details.checktype == 2
                          ? '资格后审'
                          : details.checktype == 3
                          ? '集中资格预审'
                          : ''
                      "
                    />
                    <van-cell title="开标时间" :value="details.opentime" />
                    <van-cell title="开标地区" :value="details.openareaName" />
                    <van-cell title="投标截止时间" :value="details.openDate" />
                    <van-cell title="评标方法" :value="details.openprocName" />
                    <van-cell title="开标地址" :value="details.openaddr" />
                    <van-cell
                      title="是否集团外招标"
                      :value="details.marketbidding == 1 ? '是' : '否'"
                    />
                    <van-cell
                      title="项目性质"
                      :value="
                        details.bidprojectnature == 1
                          ? '应招必招'
                          : details.bidprojectnature == 2
                          ? '能招尽招'
                          : '其他'
                      "
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="商务及技术条款" name="4" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="合同签订单位" :value="details.contName" />
                    <van-cell
                      title="交货期"
                      :value="
                        details.frmtype == 1
                          ? details.delivertimestr
                          : details.delivertime
                      "
                    />
                    <van-cell
                      title="接受/不接受代理商投标"
                      :value="details.acceptagent == 1 ? '是' : '否'"
                    />
                    <van-cell
                      title="接受/不接受流通商投标"
                      :value="details.acceptdistribution == 1 ? '是' : '否'"
                    />
                    <van-cell
                      title="接受/不接受联合体投标"
                      :value="details.acceptcombo == 1 ? '是' : '否'"
                    />
                    <van-cell title="运输方式" :value="details.transit" />
                    <van-cell title="交货地点" :value="details.deliveraddr" />
                    <van-cell title="重要商务条款" :value="details.payment" />
                    <van-cell title="技术要求" :value="details.technology" />
                    <van-cell title="报价要求" :value="details.commerce" />
                    <van-cell
                      title="其他重要要求"
                      :value="details.specialrequests"
                    />
                    <van-cell title="业绩年限" :value="details.performance" />
                    <van-cell
                      title="付款方式和进度"
                      :value="details.paymentmethod"
                    />
                    <van-cell
                      title="付款方式和进度是否作为否决条件"
                      :value="details.vetocondition == 1 ? '是' : '否'"
                    />

                    <van-cell
                      title="是否委托国事招标"
                      :value="details.consigncomp"
                      v-if="appStatus == 1"
                    >
                      <template #default>
                        <van-switch
                          v-model="checked"
                          size="20px"
                          :disabled="falg || details.roleCode == ''"
                        />
                      </template>
                    </van-cell>
                    <van-cell
                      title="是否委托国事招标"
                      :value="details.consigncomp == 1004 ? '是' : '否'"
                      v-if="appStatus != 1"
                    >
                    </van-cell>
                    <van-cell
                      title="石工建本部组织招标"
                      v-if="appStatus == 1 && details.isHeadquartersApprove == 1 && details.money < 200"
                    >
                      <template #default>
                        <van-switch
                          v-model="checkedbase"
                          size="20px"
                          :disabled="!(details.roleCode == 'qyzbywy')"
                        />
                      </template>
                    </van-cell>
                    <van-cell
                      title="是否电子招标"
                      value="是"
                      v-if="!checked && appStatus == 1"
                    />
                    <van-cell
                      title="是否电子招标"
                      :value="
                        details.openmode == 1
                          ? '是'
                          : details.openmode == 2
                          ? '是'
                          : '否'
                      "
                      v-if="appStatus != 1"
                    />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <van-tab title="包内详情" name="b" id="box">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNamesdion" accordion>
                <van-collapse-item
                  :name="index + 1"
                  icon="label"
                  v-for="(item, index) in packageInside"
                  :key="index"
                >
                  <template #title>
                    <div>包{{ index + 1 }}</div>
                  </template>
                  <van-cell-group class="group">
                    <van-cell
                      title="分包金额(万元)"
                      :value="item.subcontractamount"
                    />
                    <van-cell
                      title="定标/淘汰原则"
                      :value="item.packdbtt"
                      v-if="details.frmtype == 1"
                    />
                    <van-cell title="最高限价" :value="item.priceceiling" />
                    <van-cell
                      title="商务分计算方法"
                      :value="
                        item.gradecommerce == 1
                          ? '最低价法'
                          : item.gradecommerce == 2
                          ? '基准价法'
                          : item.gradecommerce == 3
                          ? '手工录入'
                          : ''
                      "
                    />
                    <van-cell title="商务分计算说明" :value="item.packffsm" />
                    <van-cell
                      title="框架协议有效期"
                      :value="item.packvalidity"
                      v-if="details.frmtype == 1"
                    />
                    <van-cell
                      title="评标委员会组成总人数"
                      :value="item.judgeCommitteeSum"
                    />
                    <van-cell title="招标人代表人数" :value="item.tenderee" />
                    <van-cell
                      title="评标委员会组成建议"
                      :value="item.packpbwy"
                    />
                    <van-cell title="附件1(料表)">
                      <div
                        v-for="(item1, index1) in item.bidAttachmentVos[0]"
                        :key="index1"
                      >
                        <a
                          v-if="item.suffix != 'rar' && item.suffix != 'zip'"
                          :href="
                            '/api-ec/fileDownload/FileuploadAction/' +
                              item1.id +
                              '-' +
                              item1.title
                          "
                          >{{ item1.title }}</a
                        >
                        <span v-else @click="onCompression">{{
                          item.title
                        }}</span>
                      </div>
                    </van-cell>
                    <van-cell title="附件2(框架/合同)">
                      <div
                        v-for="(item1, index1) in item.bidAttachmentVos[1]"
                        :key="index1"
                      >
                        <a
                          v-if="item.suffix != 'rar' && item.suffix != 'zip'"
                          :href="
                            '/api-ec/fileDownload/FileuploadAction/' +
                              item1.id +
                              '-' +
                              item1.title
                          "
                          >{{ item1.title }}</a
                        >
                        <span v-else @click="onCompression">{{
                          item.title
                        }}</span>
                      </div>
                    </van-cell>
                    <van-cell title="附件3(技术要求)">
                      <div
                        v-for="(item1, index1) in item.bidAttachmentVos[2]"
                        :key="index1"
                      >
                        <a
                          v-if="item.suffix != 'rar' && item.suffix != 'zip'"
                          :href="
                            '/api-ec/fileDownload/FileuploadAction/' +
                              item1.id +
                              '-' +
                              item1.title
                          "
                          >{{ item1.title }}</a
                        >
                        <span v-else @click="onCompression">{{
                          item.title
                        }}</span>
                      </div>
                    </van-cell>
                    <van-cell title="附件6(其他)">
                      <div
                        v-for="(item1, index1) in item.bidAttachmentVos[3]"
                        :key="index1"
                      >
                        <a
                          v-if="item.suffix != 'rar' && item.suffix != 'zip'"
                          :href="
                            '/api-ec/fileDownload/FileuploadAction/' +
                              item1.id +
                              '-' +
                              item1.title
                          "
                          >{{ item1.title }}</a
                        >
                        <span v-else @click="onCompression">{{
                          item.title
                        }}</span>
                      </div>
                    </van-cell>
                    <van-cell title="内部附件">
                      <div
                        v-for="(item1, index1) in item.bidAttachmentVos[4]"
                        :key="index1"
                      >
                        <a
                          v-if="item.suffix != 'rar' && item.suffix != 'zip'"
                          :href="
                            '/api-ec/fileDownload/FileuploadAction/' +
                              item1.id +
                              '-' +
                              item1.title
                          "
                          >{{ item1.title }}</a
                        >
                        <span v-else @click="onCompression">{{
                          item.title
                        }}</span>
                      </div>
                    </van-cell>
                    <van-cell
                      title="中标服务费收取标准"
                      :value="
                        item.servicestandard == 1 ? '特殊标准' : '正常标准'
                      "
                    />
                    <van-cell title="评标方法" :value="item.openProcName" />
                    <van-cell title="评标办法" :value="item.methodName" />
                    <van-cell
                      title="供应商是否可以查看评标办法的分值"
                      :value="item.isshowbidmethod == 1 ? '是' : '否'"
                    />
                    <van-cell title="物资详情" :value="item.itemname">
                      <template #default>
                        <span
                          style="color: #2d8aff"
                          @click="ongoods(item.id, index)"
                          >查看</span
                        >
                      </template>
                    </van-cell>
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <van-tab title="联系人" name="c" id="box">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="penson">
                <van-collapse-item title="企业咨询联系人" name="1" icon="label">
                  <van-cell-group
                    class="group"
                    v-for="(item, index) in pensonAr[0]"
                    :key="index"
                  >
                    <van-cell
                      title="单位"
                      :value="item.corp"
                      style="margin-top: 6px"
                    />
                    <van-cell title="联系人" :value="item.name" />
                    <van-cell title="电话" :value="item.tel" />
                    <van-cell title="Email" :value="item.email" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="技术咨询联系人"
                  name="2"
                  icon="label"
                  v-if="pensonAr[1] != null"
                >
                  <van-cell-group
                    class="group"
                    v-for="(item, index) in pensonAr[1]"
                    :key="index"
                  >
                    <van-cell
                      title="单位"
                      :value="item.corp"
                      style="margin-top: 6px"
                    />
                    <van-cell title="联系人" :value="item.name" />
                    <van-cell title="电话" :value="item.tel" />
                    <van-cell title="Email" :value="item.email" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="设置资格条件责任人"
                  name="3"
                  icon="label"
                  v-if="pensonAr[2] != null"
                >
                  <van-cell-group
                    class="group"
                    v-for="(item, index) in pensonAr[2]"
                    :key="index"
                  >
                    <van-cell
                      title="责任部门"
                      :value="item.corp"
                      style="margin-top: 6px"
                    />
                    <van-cell title="责任人" :value="item.name" />
                    <van-cell title="电话" :value="item.tel" />
                    <van-cell title="Email" :value="item.email" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="设置技术条件责任人"
                  name="4"
                  icon="label"
                  v-if="pensonAr[3] != null"
                >
                  <van-cell-group
                    class="group"
                    v-for="(item, index) in pensonAr[3]"
                    :key="index"
                  >
                    <van-cell
                      title="责任部门"
                      :value="item.corp"
                      style="margin-top: 6px"
                    />
                    <van-cell title="责任人" :value="item.name" />
                    <van-cell title="电话" :value="item.tel" />
                    <van-cell title="Email" :value="item.email" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="设置商务条件责任人"
                  name="4"
                  icon="label"
                  v-if="pensonAr[4] != null"
                >
                  <van-cell-group
                    class="group"
                    v-for="(item, index) in pensonAr[4]"
                    :key="index"
                  >
                    <van-cell
                      title="责任部门"
                      :value="item.corp"
                      style="margin-top: 6px"
                    />
                    <van-cell title="责任人" :value="item.name" />
                    <van-cell title="电话" :value="item.tel" />
                    <van-cell title="Email" :value="item.email" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="招标商联系人"
                  name="4"
                  icon="label"
                  v-if="pensonAr[5] != null"
                >
                  <van-cell-group
                    class="group"
                    v-for="(item, index) in pensonAr[5]"
                    :key="index"
                  >
                    <van-cell
                      title="供应商"
                      :value="item.supplierName"
                      style="margin-top: 6px"
                    />
                    <van-cell title="联系人" :value="item.name" />
                    <van-cell title="电话" :value="item.tel" />
                    <van-cell title="手机" :value="item.mobile" />
                    <van-cell title="Email" :value="item.email" />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <van-tab title="审批记录" name="d" v-if="appStatus != 1">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              :immediate-check="false"
            >
              <div
                class="record"
                v-for="(item, index) in projectlist"
                :key="index"
              >
                <van-cell-group>
                  <van-cell title="审批层级" :value="item.node"></van-cell>
                  <van-cell title="审批节点" :value="item.nodename"></van-cell>
                  <van-cell title="审批人" :value="item.userName"></van-cell>
                  <van-cell
                    title="审批时间"
                    :value="item.endDateTimeStr"
                  ></van-cell>
                  <van-cell
                    title="审批结论"
                    :value="item.resultType"
                  ></van-cell>
                  <van-cell
                    title="审批意见"
                    :value="item.result"
                    v-if="item.resultType == '不同意'"
                  ></van-cell>
                </van-cell-group>
              </div>
            </van-list>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div
      class="footer"
      id="activelifive"
      v-if="appStatus == 1 && showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      submitflag: false,
      show: true,
      loadingfalg: true,
      hierarchy: "",
      title: "招标委托单审批",
      showage: true,
      tender: [],
      consulting: [],
      enterprise: [],
      pensonAr: [],
      levels: null,
      openMode: 0,
      consignComp: "",
      numberrows: "4",
      // falg: true,
      falg: false,
      pageIndex: 1,
      pageSize: 10,
      checked: true,
      checkedbase: false,
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      projectlist: [],
      activeNamesdion: 1,
      penson: ["1", "2", "3", "4"],
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      packageInside: [],
      basicTop: {
        data: [],
        columns: [
          {
            prop: "goods",
            label: "招标物资",
          },
          {
            prop: "money",
            label: "概算金额(万元)",
          },
          {
            prop: "frmtypeName",
            label: "业务类型",
          },
          {
            prop: "itemname",
            label: "项目名称",
          },
          {
            prop: "opentime",
            label: "开标时间",
          },
          {
            prop: "tendermodeName",
            label: "招标方式",
          },
          {
            prop: "directionName",
            label: "签约方式",
          },
        ],
      },
    };
  },
  created() {
    this.toConsignInfo();
    this.toPacksInfo();
    this.toContactsInfo();
    if (this.appStatus != 1) {
      this.toApprovesBidInfo();
    }

    if (this.$route.query.typeof == 1) {
      this.activeNamesdion = Number(this.$route.query.activeNamesdion);
      this.active = "b";
    }
  },
  mounted() {},

  computed: {
    stockaffirmId() {
      return this.$route.query.id;
    },
    typeof() {
      return this.$route.query.typeof;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },

  watch: {
    'checked': {
      handler: function(val) {
        if (this.details.openmode == null || this.details.openmode == 2) {
          // 不执行操作
          if (this.details.roleCode == 'qyzbywy') {
            if (this.details.money < 50) {
              this.checked = false
              this.checkedbase = false
            } else {
              this.checkedbase = !val
            }
          }
        } else {
          if(this.details.money >= 50) {
            this.checkedbase = !val
          } else {
            if (val) {
              this.checkedbase = false
            }
          }
        }
      }
    },

    'checkedbase': {
      handler: function(val) {
        if (this.details.openmode == null || this.details.openmode == 2) {
          if (this.details.roleCode == 'qyzbywy') {
            if (this.details.money < 50) {
              this.checked = false
              this.checkedbase = false
            } else {
              this.checked = !val
            }
          }
        } else {
          if(this.details.money >= 50) {
            this.checked = !val
          } else {
            if (val) {
              this.checked = false
            }
          }
        }
      }
    },
  },

  methods: {
    async toConsignInfo() {
      let params = {
        consignId: this.stockaffirmId,
      };
      let data = await this.$api.ec.toConsignInfo(params);
      console.log('data233', data)
      if (data) {
        this.loadingfalg = false;
      }
      this.levels = Number(data.levels);
      this.details = data;
      this.hierarchy = Number(data.wfCounts);
      this.basicTop.data = data;

      // openmode  null 0 1 2
      // app上 招标业务员允许修改

      /**
       * 第一级 科级【不可编辑】
       *  - openmode == null || 2
       *  - consignComp 这个字段如果有值就选择国事招标
       *  - entrustComp 这个字段如果有值就默认选择石工建

       * 
       * 
       * 第二级 业务员【可以编辑】  
       *  - 原来是什么保持什么
       *  - 可以进行判断了
       *  - 第二级要传openmode
       * 
       * 第三级 领导
       *   - openmode 是有值的
       */
      
      if(data.openmode == null || data.openmode == 2) {
        // 科级，没有更改权限。有什么传什么
        if (this.details.roleCode == 'qyzbywy') {
          if (data.money >= 200) {
            this.falg = true
            this.checked = true
          } else if (data.money >= 50 && data.money < 200) {
            this.falg = false
            this.checked = data.consignComp ? true : false
            this.checkedbase = true
          } else {
            this.falg = false
            this.checkedbase = false
            this.checked = false
          }
        } else {
          if (data.consigncomp != 1004 ) {
            this.checked = false
          } else {
            this.checked = true
          }
          
          if (data.entrustComp == null || data.entrustComp == undefined) {
            // 有值就是石工建
            this.checkedbase = false
          } else {
            this.checkedbase = true
          }
        }
      } else {
        // 可以修改了
        if (this.details.isnew == 0 || this.details.isnew == null) {
          console.log('vvvv', )
          if (data.money >= 200) {
            this.falg = true
            this.checked = true
          } else if (data.money >= 50 && data.money < 200) {
            this.falg = false
            this.checked = data.consignComp ? true : false
            this.checkedbase = true
          } else {
            this.falg = false
            this.checkedbase = false
            this.checked = false
          }
        } else if (this.details.isnew == 1 || this.details.isnew == 2) {
          this.falg = true;
          this.checked = true;
        }
      }

      if (this.details.frmtypeName == "一单一招") {
        this.numberrows = "2";
        this.basicTop.columns = [
          {
            prop: "goods",
            label: "招标物资",
          },
          {
            prop: "money",
            label: "概算金额(万元)",
          },
          {
            prop: "frmtypeName",
            label: "业务类型",
          },
          {
            prop: "itemname",
            label: "项目名称",
          },
          {
            prop: "opentime",
            label: "开标时间",
          },
          {
            prop: "tendermodeName",
            label: "招标方式",
          },
        ];
      }
    },
    async toContactsInfo() {
      const params = {
        consignId: this.stockaffirmId,
      };
      let data = await this.$api.ec.toContactsInfo(params);
      this.pensonAr = data.data;
    },
    onCompression() {
      Dialog.alert({
        message: "压缩包请到电脑端查看",
      }).then(() => {
        // on close
      });
    },
    ondown(item) {
      let query = {
        id: item.id,
        type: 2,
      };
      this.$router.push({ name: "preview", query });
    },
    async toPacksInfo() {
      const params = {
        consignId: this.stockaffirmId,
      };
      let data = await this.$api.ec.toPacksInfo(params);
      this.packageInside = data.data;
      if (this.packageInside) {
        this.packageInside.map((item) => {
          if (item.bidAttachmentVos[0]) {
            item.bidAttachmentVos[0].map((item1) => {
              item1.suffix = item.title.substring(
                item1.title.lastIndexOf(".") + 1
              );
            });
          }
          if (item.bidAttachmentVos[1]) {
            item.bidAttachmentVos[1].map((item1) => {
              item1.suffix = item1.title.substring(
                item1.title.lastIndexOf(".") + 1
              );
            });
          }
          if (item.bidAttachmentVos[2]) {
            item.bidAttachmentVos[2].map((item1) => {
              item1.suffix = item1.title.substring(
                item1.title.lastIndexOf(".") + 1
              );
            });
          }
          if (item.bidAttachmentVos[3]) {
            item.bidAttachmentVos[3].map((item1) => {
              item1.suffix = item1.title.substring(
                item1.title.lastIndexOf(".") + 1
              );
            });
          }
          if (item.bidAttachmentVos[4]) {
            item.bidAttachmentVos[4].map((item1) => {
              item1.suffix = item1.title.substring(
                item1.title.lastIndexOf(".") + 1
              );
            });
          }
        });
      }
      console.log(this.packageInside);
    },
    async toApprovesBidInfo() {
      const params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        consignId: this.stockaffirmId,
      };
      let data = await this.$api.ec.toApprovesBidInfo(params);
      if (data) {
        if (data.length != 0) {
          this.pageIndex++;
          this.projectlist = [...this.projectlist, ...data.data];
        }

        if (data.total != 10) {
          this.loading = true;
          this.finished = true;
        } else {
          this.loading = false;
          this.finished = false;
        }
      }
    },
    ongoods(o) {
      const query = {
        packId: o,
        activeNamesdion: this.activeNamesdion,
        stockaffirmId: this.stockaffirmId,
        appStatus: this.appStatus,
      };
      this.$router.push({ name: "goodsDetails", query });
    },
    onClickLeft() {
      if (this.showage) {
        this.$router.push({ name: "ectenderentrust" });
      } else {
        this.showage = true;
        this.title = "招标委托单审批详情";
      }
    },
    onRefresh() {
      this.loading = true;
    },
    onLoad() {},
    listDetails() {},
    async auditApply(o, message) {
      let params = {
        id: this.stockaffirmId,
        result: 1,
        approvalOpinion: "同意",
      }
      if (this.details.roleCode) {
        if (this.checked) {
          params['consigncomp'] = 1004
          params['openMode'] = 1
        } else if (this.checkedbase) {
          params['entrustcomp'] = 1
          params['openMode'] = 2
        } else if (!this.checked && !this.checkedbase) {
          if(this.details.roleCode == 'qyzbywy') {
            params['entrustcomp'] = 0
          } else {
            params['entrustcomp'] = 1
          }
          params['consignComp'] = ''
          params['openMode'] = 2
        }
      } else {
        params = {
          // 国事
          consigncomp: this.details.consigncomp,
          // 
          entrustcomp: this.details.entrustComp ? 1 : 0,
          openMode: this.details.openmode,
          id: this.stockaffirmId,
          result: 1,
          approvalOpinion: "同意",
        };
      }
      if (o == 2) {
        this.submitflag = true;
        params = {
          ...params,
          result: 0,
          approvalOpinion: message,
        };
      }
      // console.log('parrr', params)
      let data = await this.$api.ec.doBidApp(params);
      if (data) {
        Toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "ectenderentrust",
            query: { type: 1 },
          });
        }, 1000);
      }
    },
    agree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.show = false;
          this.auditApply();
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      this.title = "审批";
      this.showage = false;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.supplies {
  margin-top: 10px;
  min-height: 160px;
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 9px;
  margin: 0.3rem;

  .supplies-title {
    font-weight: 400;
    line-height: 25px;
    font-size: 13px;
  }
  .supplies-text {
    color: #8f8f8f;
    line-height: 25px;
  }
}
.record {
  margin-top: 10px;
}
/deep/.package-details {
  .van-cell {
    padding: 0.26667rem 0.96667rem;
  }
  .van-collapse-item__wrapper {
    .van-collapse-item__content {
      background-color: transparent;
    }
  }
}
/deep/.van-collapse-item__content {
  background-color: transparent;
}
</style>
